<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="dialog"
  >
    <template #activator="{ on, attrs }">
      <div v-bind="attrs" class="text-decoration-underline primary--text"
           v-on="on" >{{skuId}}</div>
<!--      <v-btn-->
<!--          text-->
<!--          color="primary"-->
<!--          v-bind="attrs"-->
<!--          v-on="on"-->
<!--      >-->
<!--        {{skuId}}-->
<!--      </v-btn>-->
    </template>
    <template #default>
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >JD产品详情
        </v-toolbar>
        <v-card-text>
          <iframe class="jd-page" :src="url"></iframe>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="dialog = !dialog"
          >关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "jdProduct",
  props:['skuId'],
  data(){
    return {
      dialog:false
    }
  },
  computed:{
    url(){

      return `https://item.m.jd.com/product/${this.skuId}.html`
    }
  }
}
</script>

<style scoped>
.jd-page{
  width: 100%;
  height: 900px;
}
</style>