<template>
  <resizeable-page>
    <template #header>
      <v-text-field
          class="mb-4"
          dense
          v-model.trim="search"
          append-icon="mdi-magnify"
          label="输入"
          outlined
          hide-details
          @click:append="getServiceOrder(1)"
          @keydown.enter="getServiceOrder(1)"
          @keydown.e.esc="clear()"
      >
        <template v-slot:label>
          <v-icon style="vertical-align: middle">
            mdi-magnify
          </v-icon>
          输入<strong>主副订单</strong>按回车查询(Esc清除)
        </template>
      </v-text-field>
    </template>
    <template #toolbar>
    </template>
    <template #filter></template>
    <template #table="tableProps">
      <v-data-table
          :headers="headers"
          :items="contract"
          calculate-widths
          :items-per-page="limit"
          :page="page"
          item-key="_id"
          :search="search"
          dense
          fixed-header
          :height="tableProps.tableHeight"
          :server-items-length="orderCount"
          @update:page="getServiceOrder"
          :footer-props="footerOption"
      >
        <template #item.商品编号="{item}">
          <jd-product :sku-id="item.商品编号"></jd-product>
        </template>
        <template #item.下单日期="{item}">
          <div>{{ item.下单日期 | timestampToYYYYMMDD }}</div>
        </template>
        <template #item.出库日期="{item}">
          <div>{{ item.出库日期 | timestampToYYYYMMDD }}</div>
        </template>
      </v-data-table>
    </template>
  </resizeable-page>
</template>

<script>
import resizeablePage from "@/components/resizeable-page";
import db from "@/plugins/db";
import jdProduct from "@/views/contract/jdProduct";
import {timestampToYYYYMMDD} from "@/utils/commonFileters";

export default {
  components: {
    resizeablePage, jdProduct
  },
  data() {
    return {
      headers: [
        {
          value: "子单号",
          text: "主品订单",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          value: "三年无忧质保订单",
          text: "服务订单",
          sortable: false,
          align: "start",
          width: 150
        },
        {
          value: "下单日期",
          text: "下单日期",
          sortable: false,
          align: "start",
          width: 120
        },
        {
          value: "出库日期",
          text: "出库日期",
          sortable: false,
          align: "start",
          width: 120
        },
        {
          value: "商品编号",
          text: "商品编号",
          sortable: false,
          align: "center",
          width: 100
        },
        {
          value: "品牌名称",
          text: "品牌名称",
          sortable: false,
          align: "start",
          width: 200
        },
        {
          value: "轮胎尺寸",
          text: "轮胎尺寸",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          value: "销量",
          text: "数量",
          sortable: false,
          align: "start",
          width: 70
        },
        {
          value: "金额",
          text: "金额",
          sortable: false,
          align: "start",
          width: 70
        },
        {
          value: "用户类型",
          text: "用户类型",
          sortable: false,
          align: "start",
          width: 80
        },
        {
          value: "订单状态",
          text: "订单状态",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          value: "商品名称",
          text: "商品名称",
          sortable: false,
          align: "start",
          width: 500
        },
      ],
      contract: [],
      search: '',
      limit: 20,
      page: 1,
      orderCount: 0,
      footerOption: {
        'items-per-page-options': [5, 10, 15, 20, -1]
      }
    }
  },
  created() {
    this.getServiceOrder(1)
  },
  methods: {
    filterOnlyCapsText() {

    },
    getServiceOrder(page) {
      let limit = this.limit

      // ['下单日期', '出库日期', '商品编号', '子单号', '用户类型', '品牌名称', '轮胎尺寸', '销量', '金额',
      // '订单状态', '三年无忧质保订单', '商品名称']
      let query = db.collection('orders-jd-tire')
      if (this.search) {
        const _ = db.command;
        let params = _.or(
            {
              '子单号': this.search
            },
            {
              '三年无忧质保订单': this.search
            }
        )
        query = query.where(params)
      }

      query.count()
          .then((res) => {
            this.orderCount = res.total
          });
      query.skip((page - 1) * limit)
          .limit(this.limit)
          .orderBy('_id', 'desc')
          .get()
          .then((res) => {
            this.contract = res.data
          });
    },
  },
  filters: {
    timestampToYYYYMMDD
  }
}
</script>

<style scoped>

</style>