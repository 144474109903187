import moment from "moment";

let timestampToYYYYMMDD = (value) => {
    if (typeof (value) === 'number') {
        return new moment(value).format('YYYY-MM-DD')
    }
    return ''
}
let timestampToYYYYMMDDH24 = (value) => {
    if (typeof (value) === 'number') {
        return new moment(value).format('YYYY-MM-DD HH:mm:ss')
    }
    return ''
}

export {
    timestampToYYYYMMDD,
    timestampToYYYYMMDDH24
}